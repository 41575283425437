import React, { useEffect, useState } from "react";
import BlogLists from "../../components/sections/BlogLists";
import PageTitle from "../../components/sections/PageTitle";
import Swal from "sweetalert2";
import api from "../../api/api";

function BlogList() {
  const [article, setArticle] = useState([]);
  useEffect(() => {
    api
      .getData("/web-content/article/*")
      .then((res) => {
        setArticle(res.data.data.article);
      })
      .catch((err) => {
        Swal.fire(
          "Terjadi kesalahan!",
          "Silahkan refresh halaman ini!",
          "error"
        );
      });
  }, []);
  return (
    <>
      <PageTitle page_title="Blog" />
      {Object.keys(article).map((el) => {
            return <BlogLists data={article[el]}/>;
          })}
      
    </>
  );
}

export default BlogList;
