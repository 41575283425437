import React from "react";
import {
  call1,
  call2,
  call3,
  call4,
  call5,
} from "../../assets/img/callforpaper";

function CallPaper() {
  return (
    <section data-aos="fade-up" class="appie-team-area pt-140 pb-180">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="appie-section-title text-center">
              <h3 class="appie-title">Call For Paper</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div
              class="appie-team-item mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div class="thumb">
                <img src={call5} alt="" />
                <ul>
                  <li>
                    <a href="https://api.whatsapp.com/send/?phone=6281234566573&text&type=phone_number&app_absent=0">
                      <i class="fas fa-phone"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="appie-team-item mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div class="thumb">
                <img src={call2} alt="" />
                <ul>
                  <li>
                    <a href="https://api.whatsapp.com/send/?phone=6281234566573&text&type=phone_number&app_absent=0">
                      <i class="fas fa-phone"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="appie-team-item mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div class="thumb">
                <img src={call3} alt="" />
                <ul>
                  <li>
                    <a href="https://api.whatsapp.com/send/?phone=6281234566573&text&type=phone_number&app_absent=0">
                      <i class="fas fa-phone"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="appie-team-item mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div class="thumb">
                <img src={call4} alt="" />
                <ul>
                  <li>
                    <a href="https://api.whatsapp.com/send/?phone=6281234566573&text&type=phone_number&app_absent=0">
                      <i class="fas fa-phone"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallPaper;
