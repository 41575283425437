import React, { useEffect } from 'react'
import Header from '../../components/sections/Header'
import Footer from '../../components/sections/Footer'
import { Outlet } from 'react-router-dom'
import { WhatsAppWidget } from 'react-whatsapp-widget'
import "react-whatsapp-widget/dist/index.css";

import AOS from "aos";
import "aos/dist/aos.css";

function Blog() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <>
    <Header/>
    <Outlet/>
    <Footer/>
    <WhatsAppWidget
        phoneNumber={localStorage.getItem('whatsapp') && localStorage.getItem('whatsapp')}
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </>
  )
}

export default Blog