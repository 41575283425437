import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { blog_placeholder_img } from "../../assets/img";
import { blog_3 } from "../../assets/img/blog";
import api from "../../api/api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

function Blog() {
  const [article, setArticle] = useState([]);

  useEffect(() => {
    api
      .getData("/web-content/article/3")
      .then((res) => {
        setArticle(res.data.data.article);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Terjadi kesalahan!",
          "Silahkan refresh halaman ini!",
          "error"
        );
      });
  }, []);

  return (
    <section data-aos="fade-up" class="appie-blog-area pt-90 pb-95">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="appie-section-title text-center">
              <h3 class="appie-title">Blog</h3>
              <p>Baca tulisan tulisan terbaru dari kami.</p>
            </div>
          </div>
        </div>
        <div class="row">
          {Object.keys(article).map((el) => {
            let sel_article = article[el];
            return (
              <div class="col-lg-4 col-md-6">
                <div
                  class="appie-blog-item mt-30 wow animated fadeInUp"
                  data-wow-duration="3000ms"
                  data-wow-delay="200ms"
                >
                  <div class="thumb">
                    <img src={blog_3} alt="" />
                  </div>
                  <div class="content">
                    <div class="blog-meta">
                      <ul>
                        <li>{sel_article.human_date}</li>
                        <li>
                          <a href="#">{sel_article.category_name}</a>
                        </li>
                      </ul>
                    </div>
                    <h3 class="title">
                      <Link to={`/blog/${sel_article.slug}`}>
                        {sel_article.title}
                      </Link>
                    </h3>
                    <Link to={`/blog/${sel_article.slug}`}>
                      Baca lagi <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Blog;
