import React from "react";

function BlogDetails({children}) {
  return (
    <section class="blogpage-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="single-post-area">
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogDetails;
