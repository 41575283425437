import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { NotFound } from "./pages/error";

//assets
import "./assets/css/style.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/default.css";
import "./assets/css/responsive.css";
import Blog from "./pages/Blog/Blog";
import BlogList from "./pages/Blog/BlogList";
import BlogPost from "./pages/Blog/BlogPost";
import JurnalKami from "./pages/Jurnal/JurnalKami";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <NotFound />,
  },
  {
    path: "/blog",
    element: <Blog />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <BlogList />,
      },
      {
        path: ":slug",
        element: <BlogPost />,
      },
    ],
  },
  {
    path: "jurnal_kami",
    element: <JurnalKami />,
    errorElement: <NotFound />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
