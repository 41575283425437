import React from "react";
import Header from "../../components/sections/Header";

function NotFound() {
  return (
    <>
      <Header />
      <div class="appie-error-area pb-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="appie-error-content text-center">
                <img src="assets/images/error.png" alt="" />
                <span>Sorry!</span>
                <h3 class="title">The page can’t be found.</h3>
                <p>
                  The page you're looking for isn't available. Try with another
                  page or use the go home button below
                </p>
                <a href="#">
                  Back to Home <i class="fal fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { NotFound };
