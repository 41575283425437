import React from "react";
import {
  blog_1,
  blog_2,
  blog_3,
  blog_4,
  blog_5,
  blog_6,
  blog_7,
  blog_8,
} from "../../assets/img/blog";
import { Link } from "react-router-dom";

function BlogLists({ data }) {
  return (
    <section class="blogpage-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row">
              <div class="col-lg-6">
                <div class="post-item-1">
                  <img src={blog_2} alt="" />
                  <div class="b-post-details">
                    <div class="bp-meta">
                      <a href="#">
                        <i class="fas fa-clock"></i>
                        {data.human_date}
                      </a>
                      <a href="#">
                        <i class="fas fa-user"></i>
                        {data.written_by}
                      </a>
                    </div>
                    <h3>
                      <Link to={`/blog/${data.slug}`}>
                        {data.title}
                      </Link>
                    </h3>
                    <Link class="read-more" to={`/blog/${data.slug}`}>
                      Selengkapnya<i class="fal fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogLists;
