import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BlogDetails from "../../components/sections/BlogDetails";
import parse from "html-react-parser";
import PageTitle from "../../components/sections/PageTitle";
import api from "../../api/api";

function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .getData(`/web-content/article/detail/${slug}`)
      .then((res) => {
        setPost(res.data.data.article);
        setIsLoading(false);
      })
      .catch((err) => {
        Swal.fire("Gagal!", "Terjadi kesalahan silahkan refresh", "error");
      });
  }, []);
  return (
    <>
      <PageTitle page_title={post.title} />
      <BlogDetails>{post.content && parse(post.content)}</BlogDetails>
    </>
  );
}

export default BlogPost;
