import React from "react";
import { transpublika_logo_img } from "../../assets/img/logo";
import { Link } from "react-router-dom";
function Header() {
  return (
    <header class="appie-header-area appie-sticky">
      <div class="container">
        <div class="header-nav-box">
          <div class="row align-items-center">
            <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
              <div class="appie-logo-box">
                <a href="/">
                  <img
                    src={
                      localStorage.getItem("web-logo")
                        ? localStorage.getItem("web-logo")
                        : transpublika_logo_img
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
              <div class="appie-header-main-menu">
                <ul>
                  <li>
                    <a href="/#home">Home</a>
                  </li>
                  <li>
                    <a href="/#services">Tentang</a>
                  </li>
                  <li>
                    <a href="/#services">Layanan Kami</a>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/jurnal_kami">Jurnal Kami</Link>
                  </li>
                  <li>
                    <a href="/#contact">Kontak</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
              <div class="appie-btn-box text-right">
                <a
                  class=" animated_btn ml-30 btn btn-primary btn-sm"
                  href={
                    localStorage.getItem("link-form")
                      ? localStorage.getItem("link-form")
                      : "https://docs.google.com/forms/d/e/1FAIpQLSct_uBYuW8-M-F8gvUUjjsRQUKllytMhI_1d7WrQzAl-r8N4A/viewform"
                  }
                >
                  Hubungi Sekarang
                </a>
                <div class="toggle-btn ml-30 canvas_open d-lg-none d-block">
                  <i class="fa fa-bars"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
