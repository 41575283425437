import React from 'react'
import { copernicus_index_img, crossreff_index_img, thompson_index_img, wof_index_img } from '../../assets/img/indexing'

function Indexing() {
  return (
    <section data-aos="fade-up" class="appie-sponser-area appie-sponser-10-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">Layanan Indeksasi</h3>
                        <p>Artikel anda akan terindeks di berbagai database.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-sponser-box d-flex justify-content-center">
                        <div class="sponser-item">
                            <img src={copernicus_index_img} alt="" />
                        </div>
                        <div class="sponser-item">
                            <img src={crossreff_index_img} alt="" />
                        </div>
                        <div class="sponser-item">
                            <img src={thompson_index_img} alt="" />
                        </div>
                        <div class="sponser-item">
                            <img src={wof_index_img} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sponser-shape">
            <img src="assets/images/sponser-shape.png" alt="" />
        </div>
    </section>
  )
}

export default Indexing