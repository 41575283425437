import React from "react";
import { girl_wisuda_imgs } from "../../assets/img";

function Hero() {
  return (
    <section id="home" data-aos="fade-up" class="appie-hero-area">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-6">
            <div class="appie-hero-content">
              <span>Selamat Datang di Publikasijurnal.co.id</span>
              <h1 class="appie-title">
                {localStorage.getItem("web-title")
                  ? localStorage.getItem("web-title")
                  : "Jasa Publikasi Jurnal Terakreditasi"}
              </h1>
              <p>
                {localStorage.getItem("tag-line")
                  ? localStorage.getItem("tag-line")
                  : "Layanan Jasa Publikasi Artikel yang kami tangani diperuntukkan bagi Semua Mahasiswa, Akademisi atau Dosen Sebagai syarat kelulusan (S1 / S2 / S3 / UMUM) atau Angka Kredit Pengajuan Jabatan Fungsional (Jafung) (UMUM)"}
              </p>
              <ul>
                <li>
                  <a href={
                    localStorage.getItem("link-form")
                      ? localStorage.getItem("link-form")
                      : "https://docs.google.com/forms/d/e/1FAIpQLSct_uBYuW8-M-F8gvUUjjsRQUKllytMhI_1d7WrQzAl-r8N4A/viewform"
                  }>
                    {" "}
                    Kirim Artikel
                  </a>
                </li>
                <li>
                  <a
                    class="item-2"
                    href={`https://api.whatsapp.com/send/?phone=${localStorage.getItem('whatsapp') ? localStorage.getItem('whatsapp') : '6281234566573'}&text&type=phone_number&app_absent=0`}
                  >
                    {" "}
                    Konsultasi Gratis
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appie-hero-thumb">
              <div class="thumb-s2">
                <img src={girl_wisuda_imgs} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
