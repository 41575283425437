import React from "react";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import PageTitle from "../../components/sections/PageTitle";
import OurJurnal from "../../components/sections/OurJurnal";

import { WhatsAppWidget } from 'react-whatsapp-widget'
import "react-whatsapp-widget/dist/index.css";

function JurnalKami() {
  return (
    <>
      <Header />
      <PageTitle page_title="Jurnal Kami" />
      <OurJurnal/>
      <Footer />
      <WhatsAppWidget
        phoneNumber={localStorage.getItem('whatsapp') && localStorage.getItem('whatsapp')}
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </>
  );
}

export default JurnalKami;
