import React from "react";
import { AiFillPrinter, AiOutlineTranslation } from "react-icons/ai";
import {
  FaAssistiveListeningSystems,
  FaJournalWhills,
  FaSearch,
} from "react-icons/fa";
import { BiText } from "react-icons/bi";

function Services() {
  return (
    <section id="services" data-aos="fade-up" class="appie-service-area pt-100 pb-40" >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="appie-section-title text-center">
              <h3 class="appie-title">Layanan Kami</h3>
              <p>
                Selain menerbitkan artikel di jurnal terakreditasi nasional dan
                bereputasi internasional, Transpublika juga berkomitmen
                memberikan dukungan kepada para akademisi, dosen dan peneliti
                dalam menyebarkan hasil karya ilmiah dan publikasi di jurnal
                internasional, yang diwujudkan ke dalam layanan kepenulisan,
                Penerjemahan dan Publikasi..{" "}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div
              class="appie-single-service text-center mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div class="icon">
                <AiOutlineTranslation size={30} color="white" />
                <span>1</span>
              </div>
              <h4 class="appie-title">Translate / Penerjemahan</h4>
              <p class="text-left">
                Kami menangani semua jenis proyek terjemahan setiap hari. Tim
                penerjemah kami yang berpengalaman dan berkualifikasi akan
                melakukan yang terbaik untuk menangani semua kebutuhan
                terjemahan Anda. Kami Sangat Mengedepankan Kualitas!.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div class="icon">
                <FaSearch size={30} color="white" />
                <span>2</span>
              </div>
              <h4 class="appie-title">Proofread</h4>
              <p class="text-left">
                Merupakan layanan penyuntingan bahasa untuk naskah artikel
                ilmiah yang disiapkan untuk publikasi ke jurnal nasional dan
                internasional (semua pengindeks). Layanan disupervisi oleh
                editor bahasa native speaker (penutur asli Bahasa Inggris)
                bergelar Ph.D / M.Ed..
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div class="icon">
                <img src="assets/images/icon/3.png" alt="" />
                <span>3</span>
              </div>
              <h4 class="appie-title">
                {" "}
                Paraphrase (untuk menurunkan similarity)
              </h4>
              <p class="text-left">
                Merupakan layanan penyuntingan bahasa untuk naskah artikel
                ilmiah yang disiapkan untuk publikasi ke jurnal nasional dan
                internasional (semua pengindeks). Layanan disupervisi oleh
                editor bahasa native speaker (penutur asli Bahasa Inggris)
                bergelar Ph.D / M.Ed.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div class="icon">
                <FaAssistiveListeningSystems size={30} color="white" />
                <span>4</span>
              </div>
              <h4 class="appie-title">Layanan Asistensi Publikasi</h4>
              <p class="text-left">
                Layanan ini bertujuan membantu Anda mengubah bahan tulisan (bisa
                berupa gagasan, skripsi, tesis, disertasi, artikel, maupun modul
                ajar) menjadi artikel yang layak dipublikasikan..
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div class="icon">
                <FaJournalWhills size={30} color="white" />
                <span>5</span>
              </div>
              <h4 class="appie-title">Asistensi Pengelolaan Jurnal</h4>
              <p class="text-left">
                {" "}
                Layanan Asistensi Pengelolaan Jurnal ( Pembuatan Jurnal OJS,
                Indeksasi, Kustomisasi, Setting DOI dan Akreditasi Jurnal)
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div class="icon">
                <FaJournalWhills size={30} color="white" />
                <span>6</span>
              </div>
              <h4 class="appie-title">
                Layanan Asistensi Publikasi atau Lembaga
              </h4>
              <p class="text-left">
                Layanan Asistensi Pengelolaan Jurnal ( Pembuatan Jurnal OJS,
                Indeksasi, Kustomisasi, Setting DOI dan Akreditasi Jurnal )
                Institusi atau Lembaga
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div class="icon">
                <AiFillPrinter size={30} color="white" />
                <span>7</span>
              </div>
              <h4 class="appie-title">Percetakan Buku ISBN</h4>
              <p class="text-left">Layanan pencetakan Buku ISBN</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
