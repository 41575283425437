import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import {
    TB,
    TbSquareRoundedNumber1,
    TbSquareRoundedNumber2,
    TbSquareRoundedNumber3,
    TbSquareRoundedNumber4,
    TbSquareRoundedNumber5,
  } from "react-icons/tb";
import { mockup_side_img } from "../../assets/img";

function Syarat() {
  return (
    <section data-aos="fade-up" class="appie-traffic-area pt-140 pb-180">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 mb-4">
            <div class="appie-traffic-title">
              <span>Syarat dan Alur Publikasi</span>
              <h3 class="title">Syarat Publikasi</h3>
              <p>Hal yang harus dipenuhi sebelum dilakukan publikasi.</p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="appie-traffic-service mb-30">
                  <div class="icon">
                  <FaCheckCircle size={25} color="#1e2e83"/>
                  </div>
                  <h5 class="title">Artikel</h5>
                  <p>
                    Pengguna Jasa Sudah Memiliki Artikel sebagai bahan untuk
                    dipublikasikan baik dalam Bahasa Indonesia / Bahasa Inggris.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="appie-traffic-service item-2 mb-30">
                  <div class="icon">
                    <FaCheckCircle size={25} color="#1e2e83"/>
                  </div>
                  <h5 class="title">Alih Bahasa</h5>
                  <p>
                    Jika artikel Masih dalam Bahasa Indonesia, Kami akan
                    Melakukan alih bahasa secara profesional dengan tarif
                    tambahan.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="appie-traffic-title">
              <h3 class="title">Alur Publikasi</h3>
              <p>
                Alur publikasi yang akan dilewati jika publish jurnal di
                publikasijurnal.com.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="appie-traffic-service mb-30">
                  <div class="icon" style={{top:0}}>
                  <TbSquareRoundedNumber1 size={25} color="#1e2e83" />
                  </div>
                  <h5 class="title m-0 p-0">Konsultasi</h5>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="appie-traffic-service item-2 mb-30">
                  <div class="icon" style={{top:0}}>
                  <TbSquareRoundedNumber2 size={25} color="#1e2e83" />
                  </div>
                  <h5 class="title">Kirim Artikel</h5>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="appie-traffic-service item-2 mb-30">
                  <div class="icon" style={{top:0}}>
                  <TbSquareRoundedNumber3 size={25} color="#1e2e83"/>
                  </div>
                  <h5 class="title">Informasi Biaya</h5>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="appie-traffic-service item-2 mb-30">
                  <div class="icon" style={{top:0}}>
                  <TbSquareRoundedNumber4 size={25} color="#1e2e83"/>
                  </div>
                  <h5 class="title">DP 50%</h5>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="appie-traffic-service item-2 mb-30">
                  <div class="icon" style={{top:0}}>
                  <TbSquareRoundedNumber5 size={25} color="#1e2e83"/>
                  </div>
                  <h5 class="title">Artikel Terpublikasikan</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="traffic-thumb ">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src={mockup_side_img}
          alt=""
        />
      </div>
    </section>
  );
}

export default Syarat;
