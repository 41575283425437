import React, { useEffect } from "react";
import Header from "../../components/sections/Header";
import Hero from "../../components/sections/Hero";
import Services from "../../components/sections/Services";
import Syarat from "../../components/sections/Syarat";
import Testimonials from "../../components/sections/Testimonials";
import Faq from "../../components/sections/Faq";
import Footer from "../../components/sections/Footer";
import Blog from "../../components/sections/Blog";
import Indexing from "../../components/sections/Indexing";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import Contact from "../../components/sections/Contact";
import CallPaper from "../../components/sections/CallPaper";
import {ApiProvider} from "../../context/ApiProvider"

import AOS from "aos";
import "aos/dist/aos.css";

function HomePage() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <ApiProvider>
      <Header />
      <Hero />
      <Services />
      <CallPaper />
      <Syarat />
      <Indexing />
      <Testimonials />
      <Blog />
      <Contact />
      <Footer />
      <WhatsAppWidget
        phoneNumber={localStorage.getItem('whatsapp') && localStorage.getItem('whatsapp')}
        companyName="Transpublika"
        message="Hallo! 👋🏼 Apa yang bisa saya bantu?"
        sendButtonText="Kirim"
        inputPlaceHolder="Tulis pesan"
        replyTimeText="Akan dibalas secepatnya"
      />
    </ApiProvider>
  );
}

export default HomePage;
