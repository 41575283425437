import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FaUserCircle } from "react-icons/fa";

function Testimonials() {
  return (
    <section data-aos="fade-up" class="appie-testimonial-area pt-100" id="testimonial">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <Carousel autoPlay={true} showArrows={true} showStatus={false} showIndicators={false} infiniteLoop={true}>
              <div class="appie-testimonial-slider">
                <div class="appie-testimonial-item text-center">
                  <div class="author-info">
                    <FaUserCircle color="white" size={50}/>
                    <h5 class="title">Pak N</h5>
                    <span>Program Doktor Manajemen Pendidikan</span>
                  </div>
                  <div class="text">
                    <p>
                      Pak N sampaikan Bagaimana kebaikan dan profesionalnya Pak
                      Veri membantu dan memfasilitasi tugas-tugas Pak N Sampai
                      bisa selesai Doktor on time. Alhamdulillah saya sangat
                      bersemangat dan punya harapan baik untuk bisa selesai prof
                      S3 on time juga dalam berkat Tuhan
                    </p>
                    <ul>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="appie-testimonial-slider">
                <div class="appie-testimonial-item text-center">
                  <div class="author-info">
                    <FaUserCircle color="white" size={50}/>
                    <h5 class="title">IAN</h5>
                    <span>S2 Ilmu Psikologi Universitas Surabaya</span>
                  </div>
                  <div class="text">
                    <p>
                    Di atas adalah contoh letter of acceptance yang diberikan pengelola jurnal Univ Medan Area bahwa artikel tugas PSI Lintas Budaya hasil karya IAN sudah accepted dan akan diterbitkan Juli 2021. Jadi ia sudah memenuhi persyaratan untuk lulus S2 setelah lulus ujian semua mata kuliah dan Tesis.
                    </p>
                    <ul>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="appie-testimonial-slider">
                <div class="appie-testimonial-item text-center">
                  <div class="author-info">
                    <FaUserCircle color="white" size={50}/>
                    <h5 class="title">V</h5>
                    <span>Pasca Sarjana, Ilmu Komunikasi</span>
                  </div>
                  <div class="text">
                    <p>
                    Assalamualaikum Mas, mau kasih kabar... Alhamdulillah thesis nya dapat A- Terima kasih banyak lho mas udah dibantu banyak, dan mau digangguin sampe malam2 sekali. Alhamdulillah mas puas dengan pelayanannya dari awal.
                    </p>
                    <ul>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                      <li>
                        <i class="fas fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
