import React from "react";
import { transpublika_logo_img } from "../../assets/img/logo";
import { FaEnvelope, FaMapMarkedAlt, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section data-aos="fade-up" class="appie-footer-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="footer-about-widget">
              <div class="logo">
                <a href="#">
                  <img
                    src={
                      localStorage.getItem("web-logo")
                        ? localStorage.getItem("web-logo")
                        : transpublika_logo_img
                    }
                    alt=""
                  />
                </a>
              </div>
              <p>
                {localStorage.getItem("alamat")
                  ? localStorage.getItem("alamat")
                  : "Bumi Royal Park Blok A-14 Bumiayu, Kedungkandang, Malang Jl. Kolonel Sugiono 143 Malang."}
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="footer-navigation">
              <h4 class="title">Website</h4>
              <ul>
                <li>
                  <a href="/#home">Home</a>
                </li>
                <li>
                  <a href="/#services">Tentang</a>
                </li>
                <li>
                  <a href="/#services">Layanan Kami</a>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/jurnal_kami">Jurnal Kami</Link>
                </li>
                <li>
                  <a href="/#contact">Kontak</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-navigation">
              <h4 class="title">Support</h4>
              <ul>
                <li>
                  <a
                    href={`https://api.whatsapp.com/send/?phone=${
                      localStorage.getItem("whatsapp")
                        ? localStorage.getItem("whatsapp")
                        : "6281234566573"
                    }&text&type=phone_number&app_absent=0`}
                  >
                    WhatsApp
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${
                      localStorage.getItem("email")
                        ? localStorage.getItem("email")
                        : "admin@publikasijurnal.co.id"
                    }`}
                  >
                    Email
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-widget-info">
              <h4 class="title">Hubungi Kami</h4>
              <ul>
                <li>
                  <a href="#">
                    <i class="fas fa-envelope"></i>{" "}
                    {localStorage.getItem("email")
                      ? localStorage.getItem("email")
                      : "admin@publikasijurnal.co.id"}
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fas fa-phone"></i>{" "}
                    {localStorage.getItem("whatsapp")
                      ? localStorage.getItem("whatsapp")
                      : "+6281234566573"}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="footer-copyright d-flex align-items-center justify-content-between pt-35">
              <div class="copyright-text">
                <p>Copyright © 2023 Publikasi Jurnal All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
