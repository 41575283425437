import React, { useContext, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import api from "../../api/api";
import { ApiContext } from "../../context/ApiProvider";
import Swal from "sweetalert2";

function Contact() {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { data } = useContext(ApiContext);

  const handleChange = (evt) => {
    const name = evt.target.name;
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    Object.keys(state).forEach((key) => {
      formData.append(key, state[key]);
    });
    api
      .postData(formData)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          Swal.fire(
            "Berhasil!",
            "Permintaan mu sudah dikirim, silahkan tunggu balasan dari admin kami ya!",
            "success"
          );
        } else {
          Swal.fire("Gagal!", res.data.message, "error");
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire("Gagal!", "Silahkan coba lagi", "error");
      });
  };

  return (
    <section id="contact" data-aos="fade-up" class="contact-section">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="contact--info-area">
              <h3>Hubungi Kami</h3>
              <p>
                Bapak/Ibu siap untuk memulai pekerjaan anda bersama kami?,
                kujungi alamat kantor kami atau hubungi kontak yang tertera,
                kami akan merespon anda sesegera mungkin.
              </p>
              <div class="single-info">
                <h5>Alamat Kantor</h5>
                <p>
                  <i class="fal fa-home"></i>
                  {localStorage.getItem('alamat') ? localStorage.getItem('alamat') : 'Bumi Royal Park Blok A-14 Bumiayu, Kedungkandang, Malang Jl. Kolonel Sugiono 143 Malang.'}
                </p>
              </div>
              <div class="single-info">
                <h5>Phone</h5>
                <p>
                  <i class="fal fa-phone"></i>
                 { localStorage.getItem('whatsapp') ? localStorage.getItem('whatsapp') : '6281234566573'}
                </p>
              </div>
              <div class="single-info">
                <h5>Support</h5>
                <p>
                  <i class="fal fa-envelope"></i>
                  support@publikasijurnal.co.id
                  <br />
                  {localStorage.getItem('email') ? localStorage.getItem('email') : 'admin@publikasijurnal.com'}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class=" p-0">
              <h4>Kontak Form</h4>
              <p>Isi form dibawah ini dan tunggu admin kami membalas ya...</p>
              <form>
                <div class="row mb-4">
                  <div class="col">
                    <div class="form-outline">
                      <label class="form-label" for="form6Example1">
                        Email
                      </label>
                      <input
                        name="email"
                        onChange={handleChange}
                        type="email"
                        id="form6Example1"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-outline">
                      <label class="form-label" for="form6Example2">
                        Nama Lengkap
                      </label>
                      <input
                        name="name"
                        onChange={handleChange}
                        type="text"
                        id="form6Example2"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="form6Example3">
                    No WhatsApp
                  </label>
                  <input
                    type="number"
                    name="no_whatsapp"
                    onChange={handleChange}
                    id="form6Example3"
                    class="form-control"
                  />
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="form6Example4">
                    Asal Kampus / Instansi
                  </label>
                  <input
                    type="text"
                    id="form6Example4"
                    name="asal_institusi"
                    onChange={handleChange}
                    class="form-control"
                  />
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="form6Example4">
                    Jabatan Akademis
                  </label>
                  <select
                    name="jabatan_akademis"
                    onChange={handleChange}
                    class="form-select form-control"
                    aria-label="Default select example"
                  >
                    <option selected>Open this select menu</option>
                    <option value="Mahasiswa Semester (1-5)">
                      {" "}
                      Mahasiswa Semester (1-5)
                    </option>
                    <option value="  Mahasiswa S1 (Semester 6 - Keatas)">
                      Mahasiswa S1 (Semester 6 - Keatas)
                    </option>
                    <option value="Mahasiswa S2">Mahasiswa S2</option>
                    <option value=" Mahasiswa S3"> Mahasiswa S3</option>
                    <option value="Dosen">Dosen</option>
                    <option value="Doktor">Doktor</option>
                    <option value="Guru Besar">Guru Besar</option>
                    <option value="Yang Lain">Yang Lain</option>
                  </select>
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="form6Example4">
                    Keperluan Jurnal
                  </label>
                  <select
                    name="keperluan_jurnal"
                    onChange={handleChange}
                    class="form-select form-control"
                    aria-label="Default select example"
                  >
                    <option selected>Open this select menu</option>
                    <option value=" Untuk Syarat Kelulusan">
                      {" "}
                      Untuk Syarat Kelulusan
                    </option>
                    <option value="Syarat Sidang Yudisium">
                      Syarat Sidang Yudisium
                    </option>
                    <option value="BKD (Dosen)">BKD (Dosen)</option>
                    <option value="Tugas Kuliah"> Tugas Kuliah</option>
                  </select>
                </div>

                <button
                  onClick={(e) => onSubmit(e)}
                  class="btn btn-primary btn-block mb-4"
                >
                  {loading ? "Sedang mengirim..." : "Kirim"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
