import React from 'react'
import { ojs1, ojs2 } from '../../assets/img'

function OurJurnal() {
  return (
    <section data-aos="fade-up" class="appie-team-area pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div
              class="appie-team-item mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div class="thumb">
                <img src={ojs1} alt="" />
                <ul>
                  <li>
                    <a href="https://ojs.transpublika.com">
                      <i class="fas fa-globe"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div
              class="appie-team-item mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div class="thumb">
                <img src={ojs2} alt="" />
                <ul>
                  <li>
                    <a href="https:transpublika.co.id/ojs">
                      <i class="fas fa-globe"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurJurnal